@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
 @import url('https://fonts.cdnfonts.com/css/optima');


body {
  margin: 0;
  padding: 0%;
  font-family: 'outfit', sans-serif;

}
*::-webkit-scrollbar {
width:0px !important;
}

/* h1,
h2,
h3,
h4,
h5,
h6,
p{
  text-wrap: balance;
} */

/* Hide scrollbar for Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color:transparent transparent;
}

/* Hide scrollbar for Edge and IE */
* {
  -ms-overflow-style: none;
}

.show_scrollbar{
      scrollbar-width: thin !important;
        scrollbar-color: #cccccc1a #1a1a1a !important;
}
.show_scrollbar::-webkit-scrollbar{

width:5px !important;
margin:5px !important;

}
.show_scrollbar::-webkit-scrollbar-thumb {
  background-color: #ccc !important;
  width: 5px;
  border-radius: 5px;
  margin: 5px !important;

  /* You can customize the color of the thumb */
  /* border-radius: 6px; */
  /* You can customize the border radius */
}


.slide-in{
  top:0;
  left:0;
  width:100%;
  height: 100vh;
  /* background: #0f0f0f; */
  transform-origin: bottom;

}
.hide_scrollbar::-webkit-scrollbar {
  display: none;

}

.hide_scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.show_scrollbar{
    scrollbar-width: 10px;
}
.ql-editor {
  font-family: 'outfit' !important;

  margin-top: 10px !important;
  font-weight: 300 !important;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}


.paystack-button {
  background-color: #bc172f;
  border: none;
  padding: 10px;
  font-family: outfit;
  font-weight: 400;
  width: 150px;
  color: #fff;
  margin-top: 20px;
  border-radius: 5px;
  cursor: pointer;

}

.paystack-button-disabled {
  background-color: #d7d7d7;
  cursor: not-allowed;
  padding: 10px;
  font-family: outfit;
  font-weight: 400;
  width: 150px;
  border: none;
  color: #9f9f9f;
  margin-top: 20px;
  border-radius: 5px;
}
.grid_lines {
  background:
    linear-gradient(to right, #dddddd27 0.5px, transparent 0.5px),
    linear-gradient(to bottom, #dddddd27 0.5px, transparent 0.5px);
  background-size: 20px 20px;
  color: #0e0d0d
}

.ql-editor {
  font-family: 'outfit' !important;
  padding: 20px 20px !important;
  margin-top: 0px !important;
  font-weight: 300 !important;
  color:#fff;
  font-size: 16px !important;
  border: none !important
}
.ql-toolbar{
    background-color: #3F4349 !important;
      border: none !important
}
.ql-container{
min-height: 300px;
      border: none !important
}
.editor{
  min-height: 100px;
}
/* Change ReactQuill toolbar icon color */
.ql-toolbar .ql-stroke {
  stroke: white !important;

  /* For icons with strokes */
}

.ql-toolbar .ql-fill {
  fill: white;
  /* For icons with fills */
}

.ql-toolbar .ql-picker {
  color: white !important;
  /* For dropdown labels */
}

.ql-toolbar .ql-picker-options {
  background-color: #333 !important;
  /* Dropdown background color for better visibility */
}

.ql-toolbar .ql-picker-item {
  color: white;
  /* Dropdown text color */
}

.ql-toolbar .ql-active {
  color: white;
  /* Active toolbar item */
  stroke: white;
  /* Active stroke */
}

.MuiModal-root:focus {
  outline: none;
}

.MuiModal-root .MuiBox-root:focus {
  outline: none;
}

.hexagon {
  width: 100px;
  /* Adjust the size */
  height: 58px;
  /* Height is calculated based on the width for symmetry */
  background-color: #ccc;
  /* Set your desired color */
  position: relative;
  margin: 20px;
}

.hexagon:before,
.hexagon:after {
  content: '';
  position: absolute;
  width: 0;
  border-left: 50px solid transparent;
  /* Half of the width */
  border-right: 50px solid transparent;
  /* Half of the width */
}

.hexagon:before {
  bottom: 100%;
  /* Position above the main rectangle */
  border-bottom: 29px solid #ccc;
  /* Half the height for the top triangle */
}

.hexagon:after {
  top: 100%;
  /* Position below the main rectangle */
  border-top: 29px solid #ccc;
  /* Half the height for the bottom triangle */
}
