/* Dark mode styles for react-date-range */
 .rdrCalendarWrapper {
 background-color: #1e1e1e;
 /* Dark background */
 color: #ffffff;
 /* Light text */
 border: 1px solid #333;
}

 .rdrDay,
 
 .rdrMonth {
 background-color: #1e1e1e;
 color: white;
}
.rdrSelected{
 color: white;
}

 .rdrDayToday .rdrDayNumber span {
 /* background-color: #444; */
 /* Today’s highlight */
 color: white !important;
}

 .rdrSelected,
 .rdrStartEdge,
 .rdrEndEdge,
 .rdrInRange {
 background-color: #bc172f;
 /* Selected date range */
 color: white;
}
 .rdrSelected {
 background-color: #bc172f;
  border-color: #bc172f !important;
 /* Selected date range */
 color: white;
}
.rdrDayHovered span,
.rdrDayToday span,
.rdrSelected {
 border-color: #bc172f !important;
 /* Change selection border */
}


 .rdrDayNumber span {
 color: white !important;
 /* Ensure all date numbers are white */
}

/* Highlight selected date properly */
 .rdrDayToday .rdrDayNumber span {
 /* background-color: #444; */
 /* Change background for today */
 color: white;
  border-color: #bc172f !important;
}

  .rdrDayToday .rdrDayNumber span::after{
 background: #bc172f !important;
  }
/* Fix text color for selected range */
 .rdrInRange,
 .rdrStartEdge,
 .rdrEndEdge {
 background-color: #b71c1c !important;
 /* Your selected date background color */
 color: white !important;
 /* Ensure text remains visible */
}

/* Adjust disabled dates */
 .rdrDayDisabled {
 color: #777 !important;
}

.rdrDayHovered span,
.rdrDayToday span,
.rdrSelected,
.rdrStartEdge,
.rdrEndEdge {
 border-color: #bc172f !important;
 /* Change selection border */
}

/* Change the selected date highlight */
.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview {
 /* background-color: #bc172f !important; */
 color: white !important;
}

/* Change focus/hover effect */
.rdrDayNumber span {
 color: white !important;
}

.rdrDayNumber span:hover {
 /* background-color: #bc172f !important; */
 color: white !important;
}