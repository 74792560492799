.ql-editor {
 font-family: 'outfit' !important;
 padding: 20px 20px !important;
 margin-top: 0px !important;
 font-weight: 300 !important;
 color: #fff;
 font-size: 12px !important;
 border: none !important
}

.ql-toolbar {
 background-color: transparent !important;
 border: 0.5px solid #ccc !important;
 border-radius: 8px 8px 0px 0px;
}

.ql-container {
 min-height: 200px;
 border: 0.5px solid #ccc !important;
  border-radius: 0px 0px 8px 8px;
}



/* Change ReactQuill toolbar icon color */
.ql-toolbar .ql-stroke {
 stroke: white !important;

 /* For icons with strokes */
}

.ql-toolbar .ql-fill {
 fill: white;
 /* For icons with fills */
}

.ql-toolbar .ql-picker {
 color: white !important;
 /* For dropdown labels */
}

.ql-toolbar .ql-picker-options {
 background-color: #333 !important;
 /* Dropdown background color for better visibility */
}

.ql-toolbar .ql-picker-item {
 color: white;
 /* Dropdown text color */
}

.ql-toolbar .ql-active {
 color: white;
 /* Active toolbar item */
 stroke: white;
 /* Active stroke */
}