
                            @import url('https://fonts.cdnfonts.com/css/outfit');

                
body{
    background-color: white;
font-family: outfit;
}
.hero{
    background:url('../assets/img/hero_background.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: top;
    /* background-position-x: 100px; */
}
.food_background{
    background: url('../assets/img/foodbackground.svg');
}
a{
    text-decoration: none;
    
}
.support{
  background: url('../assets/img/support.svg');
  background-size: 1400px;
  background-position: bottom;

}

.changing-text {
  font-size: 24px;
  transition: opacity 0.8s;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}
.editor{
color:#fff;

}


.card {
  /* max-width: 300px; */
  border: 0;
  width: 100%;
  margin-inline: auto;
}

.container-card {
  position: relative;
  border: 2px solid transparent;
  background: linear-gradient(71deg, #080509, #1a171c, #080509);
  background-clip: padding-box;
  border-radius: 8px;
  padding: 40px;

  img {
    margin-bottom: 32px;
  }
}

.bg-green-box,
.bg-white-box,
.bg-yellow-box,
.bg-blue-box {
  position: relative;
}

.bg-green-box::after,
.bg-white-box::after,
.bg-yellow-box::after,
.bg-blue-box::after {
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  content: "";
  z-index: -1;
  border-radius: 8px;
}

.bg-green-box::after {
  background: linear-gradient(71deg, #120d0e, #fcfcfc, #120d0e);
}


 .card2 {
  position: relative;
  min-width: 200px;
  height: 240px;
border:0.2px solid #211e1ee1;
  border-radius: 10px;
  /* margin: 3px; */
  transition: 0.5s;
}

.box2-text {
  
  color: #fff;
  z-index: 1 !important;
  transition: 0.5s;
  margin-bottom: 15px;
}


 .card2 .box2 {
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  background: #1a1a1a;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
}

 .card2 .box2:hover {
  transform: translateY(-10px);
}

 .card2 .box2:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgb(0, 0, 0);
}

 .card2 .box2 .content2 {
  padding: 20px;
  text-align: center;
}

.card2 .box2 .content2 h2 {
  position: absolute;
  top: -3px;
  right: 30px;
  font-size: 8rem;
  color: rgba(255, 255, 255, 0.1);
}

 .card2 .box2 .content2 h3 {
  font-size: 1.8rem;
  color: #fff;
  z-index: 1;
  transition: 0.5s;
  margin-bottom: 15px;
}



.card2 .box2 .content2 a {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  background: black;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  margin-top: 0px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}

 .card2 .box2 .content2 a:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
  background: #fff;
  color: #000;
}

.two-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.three-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
